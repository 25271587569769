<template>
  <div>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-else>
      <form>
        <!-- <div class="mb-4 px-4">
          <fieldset class="vx-row rounded-lg pb-4 pt-1" style="border-color: rgba(255,255,255,0.6)">
            <legend class="mx-3 px-2"><strong>Title</strong></legend>
            <div class="vx-col md:w-1/3 w-full my-2">
              <vs-input v-validate="'required'" class="w-full" name="title_tm" placeholder="Turkmen" v-model="banner.title_tm" />
              <span class="text-danger text-sm" v-show="errors.has('title_tm')">{{ errors.first('title_tm') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full my-2">
              <vs-input v-validate="'required'" class="w-full" name="title_ru" placeholder="Russian" v-model="banner.title_ru" />
              <span class="text-danger text-sm" v-show="errors.has('title_ru')">{{ errors.first('title_ru') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full my-2">
              <vs-input v-validate="'required'" class="w-full" name="title_en" placeholder="English" v-model="banner.title_en" />
              <span class="text-danger text-sm" v-show="errors.has('title_en')">{{ errors.first('title_en') }}</span>
            </div>
          </fieldset>
        </div>
        <div class="mb-4 px-4">
          <fieldset class="vx-row rounded-lg pt-2 pb-4" style="border-color: rgba(255,255,255,0.6)">
            <legend class="mx-3 px-2"><strong>Description</strong></legend>
            <div class="vx-col md:w-1/3 w-full my-1">
              <vs-textarea height="110px" v-validate="'required'" class="mb-0 w-full" name="description_tm" placeholder="Turkmen" v-model="banner.description_tm" />
              <span class="text-danger text-sm" v-show="errors.has('description_tm')">{{ errors.first('description_tm') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full my-1">
              <vs-textarea height="110px" v-validate="'required'" class="mb-0 w-full" name="description_ru" placeholder="Russian" v-model="banner.description_ru" />
              <span class="text-danger text-sm" v-show="errors.has('description_ru')">{{ errors.first('description_ru') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full my-1">
              <vs-textarea height="110px" v-validate="'required'" class="mb-0 w-full" name="description_en" placeholder="English" v-model="banner.description_en" />
              <span class="text-danger text-sm" v-show="errors.has('description_en')">{{ errors.first('description_en') }}</span>
            </div>
          </fieldset>
        </div> -->
        <div class="mb-4">
          <fieldset class="rounded-lg px-3 pb-2" style="border-color: rgba(255,255,255,0.6)">
            <legend class="px-2"><strong>{{ $t('attachTo') }}</strong></legend>
            <ul class="vx-row centerx px-2">
              <li class="mx-4 mt-4 mb-3 pr-2">
                <vs-radio v-validate="'required'" name="child_type" v-model="child_type" vs-value="product">{{ $t('product') }}</vs-radio>
              </li>
              <li class="mx-4 mt-4 mb-3 pr-2">
                <vs-radio v-validate="'required'" name="child_type" v-model="child_type" vs-value="collection">{{ $t('comboProduct') }}</vs-radio>
              </li>
              <li class="mx-4 mt-4 mb-3 pr-2">
                <vs-radio v-validate="'required'" name="child_type" v-model="child_type" vs-value="brand">{{ $t('brand') }}</vs-radio>
              </li>
              <li class="mx-4 mt-4 mb-3 pr-2">
                <vs-radio v-validate="'required'" name="child_type" v-model="child_type" vs-value="category">{{ $t('category') }}</vs-radio>
              </li>
              <li class="mx-4 mt-4 mb-3 pr-2">
                <vs-radio v-validate="'required'" name="child_type" v-model="child_type" vs-value="campaign">{{ $t('campaign') }}</vs-radio>
              </li>
              <li class="mx-4 mt-4 mb-3 pr-2">
                <vs-radio v-validate="'required'" name="child_type" v-model="child_type" vs-value="url">URL</vs-radio>
              </li>
            </ul>
            <span class="text-danger text-sm" v-show="errors.has('child_type')">{{ errors.first('child_type') }}</span>
            <div v-if="child_type && child_type !== 'url'" class="vx-row" :key="child_type">
              <div class="vx-col w-full mb-4 mx-2">
                <div v-if="child_type === 'category'">
                  <v-select v-validate="'required'" :options="linkOptions" name="childId" :placeholder="$t('selectOne')" v-model="banner.childId" :label="`name_${$i18n.locale}`" :reduce="opt => opt.id" />
                </div>
                <div v-else-if="child_type !== 'campaign'">
                  <v-select v-validate="'required'" :options="linkOptions" name="childId" :placeholder="$t('selectOne')" v-model="banner.childId" :label="`name_${$i18n.locale}`" :reduce="opt => opt.uuid" />
                </div>
                <div v-else>
                  <v-select v-validate="'required'" :options="linkOptions" name="childId" :placeholder="$t('selectOne')" v-model="banner.childId" :label="`title_${$i18n.locale}`" :reduce="opt => opt.uuid" />
                </div>
                <span class="text-danger text-sm" v-show="errors.has('childId')">{{ errors.first('childId') }}</span>
              </div>
            </div>
            <div v-if="child_type && child_type === 'url'" :key="child_type" class="vx-row">
              <div class="vx-col w-full mb-4 mx-2">
                <vs-input v-validate="'required|url'" data-vv-as="field" class="w-full" name="url" placeholder="URL" v-model="banner.url" />
                <span class="text-danger text-sm" v-show="errors.has('url')">{{ errors.first('url') }}</span>
              </div>
            </div>
          </fieldset>
        </div>
        <div v-if="child_type && child_type !== 'brand' && child_type !== 'category'" class="mb-4">
          <fieldset class="rounded-lg px-5 pb-2" style="border-color: rgba(255,255,255,0.6)">
            <legend class="pr-2"><strong>{{ $t('select') }}</strong></legend>
            <div class="vx-col w-full mt-3 mb-4 mx-">
              <v-select v-validate="'required'" multiple :options="categories" name="categories" :placeholder="$t('categories')" v-model="banner.child_categoryIds" :label="`name_${$i18n.locale}`" :reduce="opt => opt.id" />
            </div>
            <div class="vx-col w-full mt-3 mb-4 mx-">
              <v-select v-validate="'required'" :options="brands" name="brands" :placeholder="$t('brand')" v-model="banner.child_brandId" :label="`name_${$i18n.locale}`" :reduce="opt => opt.id" />
            </div>
          </fieldset>
        </div>
        <div class="mb-4">
          <fieldset class="rounded-lg px-3 pb-2" style="border-color: rgba(255,255,255,0.6)">
            <legend class="px-2"><strong>{{ $t('type') }} & {{ $t('state') }}</strong></legend>
            <ul class="vx-row centerx px-2">
              <div class="vx-col w-1/4 my-4">
                <v-select v-validate="'required'" :options="types" name="type" :placeholder="$t('selectOne')" v-model="banner.type" :label="`name_${$i18n.locale}`" :reduce="opt => opt.name_en" />
                <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
              </div>
              <!-- <li class="m-4">
                <vs-checkbox v-model="banner.type" vs-value="express">Express</vs-checkbox>
              </li> -->
              <li class="vx-col my-5 ml-5 mr-4 flex">
                <vs-switch v-model="banner.isActive" />
                <label class="ml-3">{{ $t('isActive') }}?</label>
              </li>
            </ul>
          </fieldset>
        </div>
        <div class="vx-row">
          <vs-button type="filled" @click.prevent="submitForm" class="ml-auto mt-4 mb-2 block">{{ $t('submit') }}</vs-button>
          <vs-button type="border" color="warning" @click.prevent="cancelForm" class="mr-auto ml-4 mt-4 mb-2 block">{{ $t('cancel') }}</vs-button>
        </div>
        <!-- <vs-upload text="Slider image" action="https://jsonplaceholder.typicode.com/posts/" @on-success="successUpload" /> -->
      </form>
    </vx-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      banner: {
        childId: null,
        type: '',
        isActive: false,
        child_categoryIds: [],
        child_brandId: null,
        url: ''
      },
      child_type: null,
      categories: [],
      brands: [],
      link: '',
      linkOptions: [],
      types: [
        {name_en: 'both', name_tm: 'Ikisi hem', name_ru: 'Оба'},
        {name_en: 'market', name_tm: 'Market', name_ru: 'Маркет'},
        {name_en: 'express', name_tm: 'Ekspress', name_ru: 'Экспресс'}
      ],
      isLoading: false
    }
  },
  watch: {
    async child_type (newVal, oldVal) {
      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
      })
      if (newVal !== oldVal) {
        if (oldVal) this.banner.childId = null
        switch (newVal) {
        case 'product':
          await this.$http.get('/products', { params: { isActive: true } }).then(response => {
            this.linkOptions = response.data.data
          })
          break
        case 'brand':
          // await this.$http.get('/brands').then(response => {
          //   this.linkOptions = response.data.data
          // })
          this.linkOptions = this.brands
          break
        case 'category':
          // await this.$http.get('/categories').then(response => {
          //   this.linkOptions = response.data.data
          // })
          this.linkOptions = this.categories
          break
        case 'campaign':
          await this.$http.get('/campaigns', { params: { isActive: true } }).then(response => {
            this.linkOptions = response.data.data
          })
          break
        case 'collection':
          await this.$http.get('/collections', { params: { isActive: true } }).then(response => {
            this.linkOptions = response.data.data
          })
          break
        }
      }
    }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      await this.$http.get('/brands', { params: { isActive: true } }).then(response => {
        this.brands = response.data.data
      })
      await this.$http.get('/categories', { params: { isActive: true } }).then(response => {
        this.categories = response.data.data
      })
      await this.$http.get(`/baners/${this.$route.params.id}`)
        .then((response) => { 
          this.banner = response.data
          this.child_type = response.data.child_type
          this.isLoading = false
        })
        .catch((error)   => {
          this.isLoading = false
          this.notify(this.$t('notify.error'), error.message, 'icon-alert-circle', 'danger')
        })
    },
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const banner = this.banner
          this.$http.patch(`/baners/edit/${this.banner.uuid}`, {
            child_type: this.child_type,
            childId: banner.childId,
            url: banner.url,
            isActive: banner.isActive,
            type: banner.type
          }).then(response => {
            if (response.status === 200) {
              this.notify(this.$t('notify.success'), `${this.$t('banner')} ${this.$t('notify.sUpdated')}`, 'icon-check-circle', 'primary')
              this.$router.push('/banners')
            }
          }).catch(err => {
            this.notify(this.$t('notify.error'), err.message, 'icon-alert-circle', 'danger')
          })
        } else {
          this.notify(this.$t('notify.error'), this.$t('notify.formInvalid'), 'icon-alert-circle', 'danger')
        }
      })
    },
    cancelForm () {
      this.$router.back()
    },
    notify (title, text, icon, color) {
      this.$vs.notify({ title, text, iconPack: 'feather', icon, color })
    }
  }
}
</script>

<style lang="scss" scoped>
.theme-dark {
  fieldset {
    border-color: #435473 !important;
  }
}
</style>
